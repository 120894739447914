import { Checkbox, FormControlLabel } from '@mui/material';
import * as React from 'react';
import ButtonInput from '../button'
import './questionCheckBox.scss';

export default function QuestionCheckBox(props) {

    const [defaultValues, setDefaultValues] = React.useState([]);
    const [enableButton, setEnableButton] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setDefaultValues(props.defaultValue);
    }, [props.defaultValue])

    const onChangeValues = (values) => {
        let tempDefaultValues = [...defaultValues];
        tempDefaultValues = tempDefaultValues.filter((obj) => obj !== '')
        if (tempDefaultValues.filter((obj) => parseInt(obj) === values).length > 0) {
            tempDefaultValues = tempDefaultValues.filter((obj) => parseInt(obj) !== values)
        } else {
            tempDefaultValues.push(values);
        }

        if (tempDefaultValues.length >= 1) {
            let isNone = false;
            props.option.map((obj) => {
                if (obj.OptionText === 'None') {
                    if (values !== obj.ID) {
                        tempDefaultValues = tempDefaultValues.filter((objj) => objj !== obj.ID);
                    } else {
                        isNone = true;
                    }
                }
            });

            if (isNone) {
                tempDefaultValues = [values];
            }

            setDefaultValues(tempDefaultValues);
            setEnableButton(true);
            setShowError(false);
        } else {
            props.option.map((obj) => {
                if (obj.OptionText === 'None') {
                    tempDefaultValues.push(obj.ID)
                }
            });


            if (tempDefaultValues.length === 0) {
                setShowError(true);
            } else {
                setDefaultValues(tempDefaultValues);
            }
        }

        if (props.defaultValue.filter((obj) => obj !== '').length !== 0 && tempDefaultValues.length > 0) {
            props.valueChange(props.questionIndex, tempDefaultValues, props.displayOrder);
        }
    }

    return (
        <div className='padding-top-10 cus-checkbox-parent'>
            {
                props.option.map((obj, index) => {
                    return (
                        <div style={{display:"block"}}>
                            <FormControlLabel
                                control={
                                    <Checkbox className='yellow-font'
                                        checked={defaultValues.filter((subObj) => parseInt(subObj) === obj.ID).length > 0 ? true : false}
                                        onChange={() => {
                                            onChangeValues(obj.ID);
                                        }}
                                        size={'small'}
                                    />
                                }
                                label={obj.OptionText}
                                className='cus-label2 font-transform'
                                // style={{ display: 'block' }}
                            >
                            </FormControlLabel>
                        </div>
                    )
                })
            }
            <div>
                {props.defaultValue.filter((obj) => obj !== '').length === 0 &&
                    <ButtonInput
                        class={`modal-button cus-res-wrap progress-footer__button${enableButton ? '--active' : '--deactive'}`}
                        disabled={!enableButton}
                        text={props.defaultValue.filter((obj) => obj !== '').length === 0 ? 'Next' : 'Save'}
                        onSubmit={() => {
                            if (props.valueChange) {
                                setEnableButton(false);
                                props.valueChange(props.questionIndex, defaultValues, props.displayOrder);
                            }
                        }}
                    ></ButtonInput>}

                {showError &&
                    <div className='cus-res-wrap yellow-font' style={{ marginRight: '20px', marginTop: '20px', color: 'red' }}>Minimum one to be selected</div>
                }
            </div>
        </div >
    );
}
