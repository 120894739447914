import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import Signin from "../pages/login/signin";
import ProfileDetail from "../pages/profileDetail";
import Progress from "../pages/progress";
import Result from "../pages/result";
import ScrollToTop from "./ScrollToTop";
import History from "../pages/history";
import Payment from "../pages/plansNSubscription/Payment";
import PlanSubscription from "../pages/plansNSubscription/PlanSubscription";
import PaymentSuccess from "../pages/plansNSubscription/PaymentSuccess";
import PaymentFailed from "../pages/plansNSubscription/PaymentFailed";
import PaymentHistory from "../pages/plansNSubscription/PaymentHistory";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Courses from "../pages/courses";

const RouteContainer = (props) => {
  useEffect(() => {
    // console.log("this is demo DAta :: ", process.env)
  }, []);

  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/login" index element={<Login />} />
      <Route path="/" element={<ScrollToTop />}>
        <Route path="/" element={<Home />} />
        <Route path="profiledetail" element={<ProfileDetail />} />
        <Route
          path="progress"
          element={<Progress key={props.user.surveyId} />}
        />
        <Route path="result" element={<Result key={props.user.surveyId} />} />
        <Route path="history" element={<History />} />
        <Route path="plans" element={<PlanSubscription />} />
        <Route path="payment" element={<Payment />} />
        <Route path="payHistory" element={<PaymentHistory />} />
        <Route path="paymentSuccess" element={<PaymentSuccess />} />
        <Route path="paymentFailed" element={<PaymentFailed />} />
        <Route path="goals" element={<Result key={props.user.surveyId} />} />
        <Route path="courses" element={<Courses />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

RouteContainer.propTypes = {};
RouteContainer.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(RouteContainer);
