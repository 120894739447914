import { AppBar, Box, Container, Grid, IconButton, List, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import './Home.scss'
import CustomSlider from '../../component/slider';
import Logo from '../../assets/images/japa_Logo.png'
import Slide1 from '../../assets/images/mainSlide1.png'
import Slide2 from '../../assets/images/mainSlide2.png'
import Slide3 from '../../assets/images/mainSlide3.png'
import Slide4 from '../../assets/images/mainSlide4.png'
import Assessments from '../../assets/images/assessments.png';
import Assessment from '../../assets/images/assessment.png';
import AboutJapa from '../../assets/images/aboutJapa.png'
import SignUpImg from '../../assets/images/signUp.png'
import VatilityScore from '../../assets/images/vatility_score.png'
import GoalTracking from '../../assets/images/goal_Tracking.png'
import FaceBook from '../../assets/images/facebook.svg'
import Youtube from '../../assets/images/yputube.svg'
import Instagram from '../../assets/images/insta.svg'
import Goals from '../../assets/images/goals.png'
import Subscribe from '../../assets/images/subscribe.png'
import JapaJourney from '../../assets/images/japaJourney.png'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { AboutJapaList, clientSliderData } from './Data';
import { Link, NavLink } from 'react-router-dom';
import { getUserData, updateUserData } from "../../redux/action/user";
import { connect } from 'react-redux';
import ResultHeader from '../../component/result/resultHeader';


const Home = (props) => {
    const images = [Slide1, Slide2, Slide3, Slide4]
    const token = localStorage.getItem("userKey");

    return (
        <div>
            {!token ? (
                <AppBar position="static" sx={{ backgroundColor: "#fefafa", boxShadow: "none", px: '5%', py: '10px' }}>
                    <Toolbar>
                        <Box edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                            <img src={Logo} alt="Japa Logo" style={{ height: 50 }} />
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <NavLink to={'/signin'} style={{ color: "black" }}>
                                Sign In
                            </NavLink>
                            <NavLink to={'/login'} style={{ color: "black", paddingLeft: "15px" }}>
                                Sign Up
                            </NavLink>
                        </Box>
                    </Toolbar>
                </AppBar>
            ) : (
                <ResultHeader />
            )}

            <CustomSlider images={images} showNavigation={false} />

            <section className="assessents topBottomSpace">
                <Container>
                    <div className="row  centercontent ">
                        <div className="col-md-7">
                            <div>
                                <Typography className='heading_1'>
                                    Who is Japa Health For?
                                </Typography>
                                <Typography className='innerTextt'>
                                    Japa Health is designed to support improvements in health and quality of life for the following conditions:
                                </Typography>
                                <Grid container spacing={2}>
                                    {AboutJapaList.map((condition, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <Typography className='innerTextt' style={{ paddingTop: "20px", fontWeight: "600" }}>
                                                {condition.title}
                                            </Typography>
                                            <ul style={{ paddingLeft: "16px", margin: 0, paddingTop: "10px" }}>
                                                {condition.items.map((item, idx) => (
                                                    <li className="innerTextt" key={idx}>{item}</li>
                                                ))}
                                            </ul>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="imagePart centercontent">
                                <img src={AboutJapa} alt="assessments" />
                            </div>
                        </div>

                    </div>


                    <Grid container spacing={4} display="flex" alignItems="center" paddingTop={"50px"}>
                        <Grid item xs={12} md={5}>
                            <div className="imagePart ">
                                <img src={Assessments} alt="assessments" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box>
                                <Typography className='heading_1'>
                                    Assessment
                                </Typography>
                                <Typography className='innerTextt'>
                                    Receive YOUR personalized health score; a truly holistic view of your overall health
                                    and wellness, based on 10 categories of health.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} display="flex" alignItems="center" paddingTop={"50px"}>
                        <Grid item xs={12} md={7}>
                            <Box>
                                <Typography className='heading_1'>
                                    Membership
                                </Typography>

                                <ul>
                                    {[
                                        "Japa Health Assessment",
                                        "Health Scores Across all Categories",
                                        "Full Access to All Japa Health Course content including videos and e-books from our world-class team",
                                        "Personalized science-backed suggestions to improve your health",
                                        "Goal Setting",
                                        "Lessons for Each Goal from our experts",
                                        "New Lessons, Recipes, and more each month",
                                        "Recommended Labs",
                                        "Unlimited Reassessments",
                                        "Daily motivational tips and Check-ins",
                                        "Group Challenges",
                                        "Private Community",
                                        "Monthly Live Q & A with Dr. Kelsey Dexter",
                                        "Discounts on Products & Live Events",
                                    ].map((item, index) => (
                                        <li key={index} className='innerTextt' >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} display="flex" justifyContent="center" >
                            <Box>
                                <img src={Subscribe} alt="assessments" style={{ maxWidth: "100%" }} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={"50px"}
                    >
                        {/* Image Section */}
                        <Grid item xs={12} md={5}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img
                                    src={Goals}
                                    alt="goals"
                                />
                            </Box>
                        </Grid>

                        {/* Text Section */}
                        <Grid item xs={12} md={7}>
                            <Box>
                                <Typography className='heading_1'>
                                    Goals
                                </Typography>
                                <Typography className='innerTextt'>
                                    The Japa Health method provides a clear starting point and roadmap for your wellness
                                    journey, offering the what, why, and how of each goal to ensure sustainable transformation.
                                </Typography>
                                <br />
                                <Typography className='innerTextt'>
                                    By scoring what’s working and what isn’t in your life, you'll gain the insights needed
                                    to navigate your path effectively, no matter where you’re starting from.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section class="client_Sliders assessents">
                <div class="imgside">

                </div>
                <Container>
                    <Box style={{ paddingBottom: "30px" }}>
                        <Typography fontSize="25px">
                            Feedback from
                        </Typography>
                        <Typography className='heading_1' textTransform={"capitalize"}>The Valuable Clients </Typography>
                    </Box>
                </Container>
            </section>

            <section className="client_Slider topBottomSpace">
                <Container>
                    <Box className="wrapper">
                        <Box className="my-client-slider">
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                spaceBetween={10}
                                centeredSlides={true}
                                loop={true}
                                navigation
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                breakpoints={{
                                    320: { slidesPerView: 1, spaceBetween: 5 },  // Mobile screens
                                    480: { slidesPerView: 1.5, spaceBetween: 10 }, // Small tablets
                                    768: { slidesPerView: 2, spaceBetween: 15 }, // Tablets
                                    1024: { slidesPerView: 3, spaceBetween: 20 }, // Desktops
                                }}
                                style={{ padding: "10px" }} // Add padding to improve layout
                            >
                                {clientSliderData.map((client) => (
                                    <SwiperSlide key={client.id}>
                                        <Box className="clientSlide" sx={{ textAlign: "center", p: 2 }}>
                                            <Box className="clientslideImgs" sx={{ display: "flex", justifyContent: "center" }}>
                                                <img src={client.image} alt={client.name} style={{ width: "80%", borderRadius: "50%" }} />
                                            </Box>
                                            <Box className="clientInfo">
                                                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: "14px", md: "18px" } }}>
                                                    {client.name}
                                                </Typography>
                                                <Typography fontSize="11px">{client.designation}</Typography>
                                                <p style={{ fontSize: "12px", margin: "5px 0" }}>{client.testimonial}</p>
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>


                        </Box>
                    </Box>
                </Container>

            </section>


            <section className="userJourney topBottomSpace">
                <div className="dotimg"></div>
                <Container>
                    <div className="row">
                        {/* <h3 className="lightText">
                                
                            </h3> */}
                        <h3 className="darkText">Your Japa Journey</h3>
                        {/* <p className="innerTextt">
                            Created by our team of self healers, scientists and doctors on the path of self healing who know what it takes. We’ve got your back!
                        </p> */}
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="col-md-3">
                            <div className="joourneyImg one">
                                <div className="dotCircle">
                                    <img src={SignUpImg} alt="Sign Up" />
                                </div>
                                <p>Sign Up</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="joourneyImg two">
                                <div className="dotCircle">
                                    <img src={Assessment} alt="Assessment" />
                                </div>
                                <p>Assessment</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="joourneyImg three">
                                <div className="dotCircle">
                                    <img src={VatilityScore} alt="Vitality Score" />
                                </div>
                                <p>Vitality Score</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="joourneyImg four">
                                <div className="dotCircle">
                                    <img src={GoalTracking} alt="Goal Tracking" />
                                </div>
                                <p>Goal Tracking</p>
                            </div>
                        </div>
                    </div> */}
                    <Box>
                        <Box sx={{ mx: 'auto', height: '500px', maxWidth: '500px' }}>
                            <img src={JapaJourney} alt='japa journey' style={{ width: '100%', height: '100%' }} />
                        </Box>
                    </Box>
                </Container>
                <div className="dotimg2"></div>
            </section>

            <section className="footer-section">
                <footer>
                    <Container>
                        <div className="footerSection">
                            <div className="footerLogo">
                                <img src={Logo} alt="Japa Logo" />
                            </div>
                            <div className="socials">
                                <div className="details">
                                    <ul>
                                        <li><Link to={'/about'}>About</Link></li>
                                        <li><Link to={'/contact'}>Contact</Link></li>
                                        <li><Link to={'/privacy'}>Privacy</Link></li>
                                        <li><Link to={'/termsandcondition'}>Terms and Condition</Link></li>
                                        <li><Link to={'/faq'}>FAQs</Link></li>
                                    </ul>
                                </div>
                                <div className="socialsIcons">
                                    <img src={FaceBook} alt='facebook' />
                                    <img src={Instagram} alt="Instagram" />
                                    <img src={Youtube} alt="You Tube" />
                                </div>
                            </div>
                        </div>
                    </Container>
                </footer>
            </section>
        </div>
    )
}

const actionCreators = {
    getUserData,
    updateUserData,
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Home);