import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

export function useBrowserFingerprint() {
    const [fingerprint, setFingerprint] = useState(null);

    useEffect(() => {
        function getBrowserFingerprint() {
            const { userAgent, platform, language } = navigator;
            const screenRes = `${window.screen.width}x${window.screen.height}`;
            const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const currentTimeMs = Date.now();

            const rawFingerprint = `${userAgent}-${platform}-${language}-${screenRes}-${currentTimeMs}-${timezoneOffsetMs}-${timezone}`;

            // Encrypt the fingerprint
            const encryptedFingerprint = CryptoJS.AES.encrypt(rawFingerprint, process.env.REACT_APP_PINGERPRINT_KEY)?.toString();

            return encryptedFingerprint;
        }

        setFingerprint(getBrowserFingerprint());
    }, []);

    return fingerprint;
}