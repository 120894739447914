import RouteContainer from "./routeContainer/RouteContainer";
import { BrowserRouter } from "react-router-dom";
import "./css/base.scss";
import ExperienceRatingModal from "./component/ExperenceRatingModal";
import "./App.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <RouteContainer />
        <ExperienceRatingModal />
      </BrowserRouter>
    </div>
  );
}

export default App;
