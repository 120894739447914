import React from "react";
import "./header.scss";
import { connect } from "react-redux";
// import headerImage from "../../assets/images/header.png";
import { useNavigate, useLocation } from "react-router-dom";
import { InputLabel } from "@mui/material";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  function signout() {
    localStorage.clear("userKey");
    localStorage.clear("_grecaptcha");
    localStorage.removeItem("review");
    sessionStorage.clear("_grecaptcha");
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate("/signin");
    // alert(
    //   "Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment"
    // );
    window.location.reload();
  }

  return (
    <header className="header-section">
      <div>
        {/* <img src={headerImage} alt={headerImage}></img> */}
        <h1 style={{color: "#000"}}>
          {props.page === "login"
            ? "Welcome Back"
            : props.page === "signup"
            ? "Welcome"
            : ""}
        </h1>
      </div>

      {location.pathname === "/profiledetail" && (
        <div className="logout">
          <InputLabel
            style={{ cursor: "pointer", paddingRight: "30px", zIndex: "1" }}
            onClick={() => signout()}
          >
            Logout
          </InputLabel>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Header);
