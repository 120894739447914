// export const ConvertUTCtoLocal = (date, time, dateTime) => {
//   // Combine the date and time into a single string (UTC time)
//   const combinedDatetime = `${date}T${time}Z`; // The "Z" at the end indicates UTC

//   // Create a new Date object, which will be in UTC
//   const utcDate = new Date(combinedDatetime);

//   // Convert to the system's local time zone
//   const localDate = utcDate.toLocaleString(); // Full local date and time

//   // Local date only (without time)
//   const localDateOnly = utcDate.toLocaleDateString(); // Local date only

//   // Local time only (without date)
//   const localTimeOnly = utcDate.toLocaleTimeString("en-US", {
//     timeZoneName: "short", // Short time zone format (e.g., GMT-4)
//   }); // Local time only

//   // Full local date and time with time zone information
//   const localDateWithTimeZone = utcDate.toLocaleString("en-US", {
//     timeZoneName: "short", // Short time zone format (e.g., GMT-4)
//   });

//   return {
//     date: localDateOnly,
//     time: localTimeOnly,
//     dateTime: localDateWithTimeZone,
//   };
// };

export const ConvertUTCtoLocal = (date, time, dateTime) => {
  // Combine the date and time into a single string (UTC time)
  const combinedDatetime = `${date}T${time}Z`; // The "Z" at the end indicates UTC

  // Create a new Date object, which will be in UTC
  const utcDate = new Date(combinedDatetime);

  // Convert to the system's local time zone
  const localDate = utcDate.toLocaleDateString(); // Local date only

  // Local time only (without date) and without seconds
  const localTimeOnly = utcDate.toLocaleTimeString("en-US", {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // 12-hour format
  }); // Local time only, no seconds, 12-hour format

  // Full local date and time with time zone information, but without seconds and time zone
  const localDateWithTimeZone = `${localDate}, ${localTimeOnly}`;

  return {
    date: localDate,
    time: localTimeOnly,
    dateTime: localDateWithTimeZone,
  };
};

