import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./collapsBlock.scss";
import bgImage from "../../assets/images/result_details_top.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonInput from "../button";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as WraningIcon } from "../../assets/images/warningRed.svg";
import {
  getDeterminants,
  initiateGoals,
  newTest,
  setUserData,
  withdrawGoals,
} from "../../redux/action/user";
import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  Chip,
  InputLabel,
  LinearProgress,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";
// import AddTaskIcon from "@mui/icons-material/AddTask";
// import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SnackBar from "../snackBar";

const CollapsBlock = forwardRef((props, ref) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [expandedList, setExpandedList] = useState([]);
  const [goal, setGoal] = useState([]);
  const [completed, setCompleted] = useState([]); // Track completed suggestions
  const [showWarningRemove, setShowWarningRemove] = useState(false);
  const [showWarningValidate, setShowWarningValidate] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [warning, setWarning] = useState({
    text: "You can only set a maximum of 5 goals.",
    type: "limit",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const isCurrentSurvey =
    props.user.surveyIdList &&
    props.user.surveyIdList?.length - 1 == localStorage.getItem("assesmentKey");
  // const [lowestScoreSuggestionId, setLowestScoreSuggestionId] = useState([]);

  const isSubscription =
    props.user &&
    props.user.subscription &&
    props.user.subscription.isSubscription;

  // const showSkeletons =
  //   (props.user.programPurchased && props.user.programPurchased?.length > 0) ||
  //   (props.user.subscription && props.user.subscription.isSubscrption === true)
  //     ? false
  //     : true;

  // const lowesttoHightest = props.data
  //   ? props.data.sort((a, b) => a.score - b.score)
  //   : [];

  const Resume =
    (props.user.surveyIdList &&
      props.user.surveyIdList.length > 0 &&
      props.user.surveyIdList[props.user.surveyIdList.length - 1]
        .s_IsSurveyCompleted !== 1) ||
    false;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      // const top4Determinants = props.data.slice(0, 4);

      // const top4Suggestions = top4Determinants
      //   .map((determinant) =>
      //     determinant.suggestion.map((suggestion) => suggestion.suggestionID)
      //   )
      //   .flat();

      // setLowestScoreSuggestionId(top4Suggestions);
      // setExpandedList(props.data.map((e) => e.ID[0]));
      setExpandedList(props.data && [props.data?.[0]?.ID]);
      const progressNot100 = getSuggestionsWithProgress(props.data);
      const progress100 = getSuggestionsWithCompelted(props.data);
      setGoal(progressNot100);
      setCompleted(progress100);
    }
  }, [props.data]);

  useEffect(() => {
    const host = window.location.host;
    if (
      host === "localhost:3000" ||
      host.search("demo") !== -1 ||
      props.deleteVisible
    ) {
      setShowDeleteButton(true);
    }
  }, []);

  useEffect(() => { }, [isSubscription]);

  useEffect(() => {
    if (props.user?.error.errormsg) {
      setErrorMessage(props.user?.error.errormsg);
    }
  }, [props.user?.error.errormsg]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  // Function to filter suggestions based on progress
  const getSuggestionsWithProgress = (suggestions) => {
    return suggestions.flatMap((item) =>
      item.suggestion
        .filter((s) => s.progress !== 100 && s.progress !== null)
        .map((s) => s.suggestionID)
    );
  };

  const getSuggestionsWithCompelted = (suggestions) => {
    return suggestions.flatMap((item) =>
      item.suggestion
        .filter((s) => s.progress === 100)
        .map((s) => s.suggestionID)
    );
  };

  const withdrawGoal = async () => {
    if (warning.data) {
      setShowWarningRemove(false);
      const res = await props.withdrawGoals(warning.data);
      if (res === 200) {
        setGoal(goal.filter((id) => id !== warning.data?.suggestionID));
        await props.getDeterminants();
      }
    }
  };

  const handleGoals = async (val, status) => {
    const data = {
      UserID: localStorage.getItem("userKey"),
      suggestionID: val.suggestionID,
      assessmentID: localStorage.getItem("assesmentKey"),
    };
    if (status === "remove") {
      setShowWarningRemove(true);
      setWarning({
        text: "Are you sure you want to reset the Goal progress?",
        type: "confirm",
        data: data,
      });
    } else {
      // const remainingTop4Suggestions = lowestScoreSuggestionId.filter(
      //   (id) => !completed.includes(id)
      // );

      // const canSelectGoalFromOthers =
      //   remainingTop4Suggestions.length === 0 || // No remaining top 4 suggestions
      //   goal.length + remainingTop4Suggestions.length < 5;

      if (goal.length >= 5) {
        setShowWarningValidate(true); // Show warning if the user has already set 5 goals
        setWarning({
          text: "You can only set a maximum of 5 goals.",
          type: "limit",
        });
      } else if (
        // !canSelectGoalFromOthers &&
        // lowestScoreSuggestionId.includes(val.suggestionID) &&
        !completed.includes(val.suggestionID)
      ) {
        const res = await props.initiateGoals(data);
        if (res === 200) {
          await props.getDeterminants();
          setGoal([...goal, val.suggestionID]);
        }
      }
      // else {
      //   setShowWarning(true);
      //   setWarning("Complete the lowest score determinants first.");
      // }
    }
  };

  useImperativeHandle(ref, () => ({
    handleGoalsCancel: (val, status) => handleGoals(val, status),
  }));
  // const markAsCompleted = (suggestionID, determinantID) => {
  //   // Mark the suggestion as completed and remove it from the goal list
  //   setCompleted([...completed, suggestionID]);
  //   setGoal(goal.filter((id) => id !== suggestionID)); // Remove from goal list
  // };

  // // Close the warning message
  // const handleCloseWarning = () => {
  //   setShowWarning(false);
  // };

  const getGoalStatus = (suggestionObj) => {
    if (!isCurrentSurvey) {
      if (completed.includes(suggestionObj.suggestionID)) {
        return {
          color: "#709640",
          showProgress: false,
          icon: "completed-icon",
          statusClass: "complete",
        };
      } else {
        return {
          color: "#ffd781",
          showProgress: false,
          icon: "archived-icon",
          statusClass: "archived",
        };
      }
    } else if (goal.includes(suggestionObj.suggestionID)) {
      return {
        color: "khaki",
        showProgress: true,
        icon: "in-progress-icon",
        statusClass: "cancel",
      };
    } else if (completed.includes(suggestionObj.suggestionID)) {
      return {
        color: "greenyellow",
        showProgress: false,
        icon: "completed-icon",
        statusClass: "complete",
      };
    } else {
      return {
        color: "",
        showProgress: false,
        icon: "default-icon",
        statusClass: "add",
      };
    }
  };

  const newAssessment = async () => {
    if (Resume) {
      localStorage.removeItem("progressDone");
      localStorage.removeItem("detailDone");
      localStorage.setItem(
        "assesmentKey",
        props.user.surveyIdList[props.user.surveyIdList.length - 1].SurveyId
      );
      dispatch(
        setUserData({
          surveyId:
            props.user.surveyIdList[props.user.surveyIdList.length - 1]
              .SurveyId,
        })
      );
      navigate("/progress");
    } else {
      await props.newTest(props.user._id, navigate);
    }
  };

  return (
    <div>
      <div className="top-result-details">
        <img src={bgImage} alt={bgImage}></img>
        <div className="ab-top-result-details">
          <p>
            <span style={{ fontSize: "24px", fontWeight: "bolder" }}>
              {isSubscription
                ? "Your Personalized Japa Journey"
                : "Free Plan: Limited Access"}
            </span>
            <Typography>
              • Scores by category are listed from lowest to hightest.
            </Typography>
            <Typography>
              {isSubscription && isCurrentSurvey
                ? "• Click on each suggestion to learn, set goals, and improve your vitality"
                : "• Click on each suggestion to learn more!"}
            </Typography>
          </p>
          {/* <div className="delete-button-div">
            {showDeleteButton && (
              <ButtonInput
                class="delete-clear-button"
                text={"Clear And Restart"}
                onSubmit={() => props.deleteUserData && props.deleteUserData()}
              />
            )}
          </div> */}
          {/* <div>
            <ButtonInput
              class="delete-clear-button"
              text={"Review Assessment"}
              onSubmit={() => {
                localStorage.removeItem("progressDone");
                localStorage.removeItem("detailDone");
                navigate("/progress");
              }}
            />
          </div> */}
          <div style={{ paddingRight: "18px" }}>
            <ButtonInput
              class="delete-clear-button"
              text={`${Resume ? "Resume" : "New Assessment"}`}
              onSubmit={() => newAssessment()}
            />
          </div>
        </div>
      </div>
      <div className="inner-bg-result">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&>p": {
              color: "#E29C2E",
              fontSize: "1.2rem",
              marginBottom: "5px",
              marginTop: "5px",
            },
          }}
        >
          <p style={{ textAlign: "justify" }}>
            {/* Click on each tip to learn more */}
            {isSubscription && isCurrentSurvey
              ? "You can work on up to 5 goals at a time!"
              : isSubscription ? "" : "Become a member to reveal all your suggestions, lessons, set goals and more!"}
            {/* The Japa Health Method is Based on the {props.data.length} Determinants of Health */}
          </p>
        </Box>
        {!isCurrentSurvey && isSubscription && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginBottom: "5px",
              "&> p": {
                "&.MuiTypography-root": {
                  position: "relative",
                  fontSize: "14px !important",
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    left: -15,
                    top: 5,
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  },
                  "&:nth-of-type(1)": {
                    mr: 3,
                    "&:before": {
                      backgroundColor: "#ffd781",
                    },
                  },
                  "&:nth-of-type(2)": {
                    "&:before": {
                      backgroundColor: "#709640",
                    },
                  },
                },
              },
            }}
          >
            <Typography>Archived</Typography>
            <Typography>Completed</Typography>
          </Box>
        )}
        <div>
          {props.data &&
            props.data.map((obj, index) => {
              return (
                <Accordion expanded={expandedList.includes(obj.ID)} key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => {
                      setExpandedList((prev) => {
                        const temp = Array.from(prev);
                        if (temp.includes(obj.ID)) {
                          temp.splice(temp.indexOf(obj.ID), 1);
                        } else {
                          temp.push(obj.ID);
                        }

                        return temp;
                      });
                    }}
                  >
                    <Typography
                      sx={{ flexShrink: 0 }}
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <div className="image-container">
                        {obj?.Icon && (
                          <img
                            src={require(`../../assets/images/determine${obj?.Icon}`)}
                            height={50}
                            minWidth={50}
                          />
                        )}
                      </div>
                      <div>{obj.Determinant}</div>
                      {(isSubscription && isCurrentSurvey && index < 4) ? (
                        <Chip label="Recommended" color="primary" />
                      ) : (isCurrentSurvey && index < 2) ? (
                        <Chip label="Recommended" color="primary" />
                      ) : null}
                    </Typography>
                    <Typography
                      className="avenir-bold"
                      sx={{ color: "text.secondary" }}
                      style={{ marginRight: "10px" }}
                    >
                      Scored -{" "}
                      {obj.score ? (
                        obj.score
                      ) : (
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={20}
                          sx={{ backgroundColor: "aliceblue" }}
                        />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {obj.isGetSuggestion ? (
                      <>
                        {/* <div className="yellow-font tooltip-title">
                          Tips to Improve
                        </div> */}
                        <Box sx={{ ...GoalListing }}>
                          <ul>
                            {obj.suggestion && obj.suggestion.length > 0 ? (
                              obj.suggestion.map((suggestionObj, subIndex) => {
                                const goalStatus = getGoalStatus(suggestionObj);
                                const suggestionObjRef = {
                                  ...suggestionObj,
                                  goalstatus: goalStatus,
                                };
                                return (
                                  <>
                                    <li
                                      className={
                                        isSubscription &&
                                        isCurrentSurvey &&
                                        goalStatus.statusClass
                                      }
                                    >
                                      {/* <Box className="IconWrap">
                                        <LightbulbIcon />
                                      </Box> */}
                                      <Box className="ContentWrap">
                                        <a
                                          onClick={() => {
                                            if (props.modelOpen) {
                                              props.modelOpen(suggestionObjRef);
                                            }
                                          }}
                                          style={{
                                            color: isCurrentSurvey
                                              ? ""
                                              : goalStatus.color,
                                          }}
                                          className="Suggestion"
                                        >
                                          {suggestionObj.Suggestion}
                                        </a>

                                        {isSubscription && isCurrentSurvey && (
                                          <Tooltip
                                            title={
                                              goalStatus.icon ===
                                                "completed-icon"
                                                ? "Completed Goal"
                                                : goalStatus.icon ===
                                                  "in-progress-icon"
                                                  ? "Remove Goal"
                                                  : "Click to add goal"
                                            }
                                          >
                                            {goalStatus.icon ===
                                              "completed-icon" ? (
                                              <Chip
                                                label="Completed"
                                                color="primary"
                                                onClick={() => console.log("")}
                                                className="Completed"
                                              />
                                            ) : goalStatus.icon ===
                                              "in-progress-icon" ? (
                                              // <Chip
                                              //   label="Cancel"
                                              //   color="warning"
                                              //   // onClick={() =>
                                              //   //   handleGoals(
                                              //   //     suggestionObj,
                                              //   //     "remove"
                                              //   //   )
                                              //   // }
                                              //   className="Cancel"
                                              // />
                                              <></>
                                            ) : (
                                              <Chip
                                                label="Add Goal"
                                                color="success"
                                                onClick={() =>
                                                  handleGoals(
                                                    suggestionObj,
                                                    "add"
                                                  )
                                                }
                                                className="add"
                                              />
                                            )}
                                          </Tooltip>
                                        )}
                                        {isSubscription &&
                                          goalStatus.showProgress && (
                                            <Stack spacing={2} sx={{ flex: 1 }}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Box
                                                  sx={{ width: "100%", mr: 1 }}
                                                >
                                                  <LinearProgress
                                                    variant="determinate"
                                                    value={
                                                      suggestionObj.progress ||
                                                      0
                                                    }
                                                  />
                                                </Box>
                                                <Box
                                                  sx={{ minWidth: 35 }}
                                                  className="progresspercent"
                                                >
                                                  <Typography
                                                    variant="body2"
                                                    sx={{
                                                      color: "text.secondary",
                                                    }}
                                                  >
                                                    {`${Math.round(
                                                      suggestionObj.progress ||
                                                      0
                                                    )}%`}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Stack>
                                          )}
                                      </Box>
                                    </li>
                                  </>
                                );
                              })
                            ) : (
                              <Fragment>
                                <div className="yellow-font tooltip-title">
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={20}
                                    sx={{ backgroundColor: "aliceblue" }}
                                  />
                                </div>
                                <ul>
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={20}
                                    sx={{ backgroundColor: "aliceblue" }}
                                  />
                                </ul>
                                <ul>
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={20}
                                    sx={{ backgroundColor: "aliceblue" }}
                                  />
                                </ul>
                                <ul>
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={20}
                                    sx={{ backgroundColor: "aliceblue" }}
                                  />
                                </ul>
                                <ul>
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={20}
                                    sx={{ backgroundColor: "aliceblue" }}
                                  />
                                </ul>
                                <InputLabel
                                  style={{
                                    cursor: "pointer",
                                    paddingRight: "30px",
                                    paddingTop: "10px",
                                    // color: "white",
                                    animation:
                                      "animation-c7515d 1.5s ease-in-out 0.5s infinite",
                                    textDecoration: "underline",
                                    color: "#E29C2E",
                                  }}
                                  onClick={() => navigate("/plans")}
                                >
                                  <Link
                                    to={""}
                                    style={{
                                      color: "#fff",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    To reveal all your suggestions, become a
                                    member here!
                                  </Link>
                                </InputLabel>
                              </Fragment>
                            )}
                          </ul>
                        </Box>
                      </>
                    ) : (
                      <ul>
                        <p className="cus-label">{obj.suggestion}</p>
                      </ul>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      </div>
      <Modal
        open={showWarningRemove}
        onClose={() => setShowWarningRemove(false)}
      >
        <Box sx={modalStyle} width={"40%"}>
          <div className="close-pop-wrap">
            <Box className="Heading">
              <WraningIcon />
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: 2, fontWeight: "bold", color: "#d32f2f" }}
              >
                Warning
              </Typography>
            </Box>
            <CloseIcon
              onClick={() => setShowWarningRemove(false)}
              className="CloseImg"
            />
            {/* <img
              src={closex}
              alt={closex}
              
              
            ></img> */}
          </div>
          <div className="ContentWrapper">
            Dropping this goal will set its progress to 0%. Are you sure you
            want to drop this goal ?{" "}
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              button: {
                "&.MuiButtonBase-root": {
                  "&.MuiButton-root": {
                    padding: "5px 10px !important",
                    height: "unset !important",
                    borderRadius: "6px !important",
                    backgroundColor: "#faaa2f !important",
                    color: "#fff !important",
                    mt: "5px !important",
                    mb: "5px !important",
                    "&:nth-of-type(1)": {
                      mr: "10px !important",
                    },
                  },
                },
              },
            }}
          >
            <ButtonInput
              // class="modal-button"
              text="Yes"
              onSubmit={() => withdrawGoal()}
            />
            <ButtonInput
              // class="modal-button"
              text="No"
              onSubmit={() => setShowWarningRemove(false)}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showWarningValidate}
        onClose={() => setShowWarningValidate(false)}
      >
        <Box sx={modalStyle} width={"32%"} maxWidth={'400px'} minWidth={"300px"}>
          <div className="close-pop-wrap">
            <Box className="Heading">
              {/* <WraningIcon /> */}
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: 2, fontWeight: "bold", color: "#d32f2f" }}
              >
                Limit Reached
              </Typography>
            </Box>
            <CloseIcon
              onClick={() => setShowWarningValidate(false)}
              className="CloseImg"
            />
          </div>
          <div className="ContentWrapper">
            You can work on up to 5 goals maximum at a time!
          </div>
        </Box>
      </Modal>
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={() => {
          setOpenSnackBar(false);
          setErrorMessage("");
        }}
      />
    </div>
  );
});

CollapsBlock.propTypes = {};
CollapsBlock.defaultProps = {};

const actionCreators = {
  newTest,
  setUserData,
  initiateGoals,
  getDeterminants,
  withdrawGoals,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(CollapsBlock);

const modalStyle = {
  overflow: "hidden",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90vh",
  backgroundColor: "white",
  boxShadow: 24,
  pb: 2,
  // padding: "20px",
  // overflowY: "auto", // Makes the content scrollable
  borderRadius: "8px",
  ".close-pop-wrap": {
    top: "0px",
    right: "0px",
    position: "sticky",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#112225",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  ".Heading": {
    display: "flex",
    alignItems: "center",
    h2: {
      mb: 0,
      color: "#fff",
    },
    svg: {
      mr: 1,
      width: "20px",
      height: "20px",
      // def:{
      //   fill:"#fff"
      // },
      // path: {
      //   fill: "#d32f2f",
      // },
    },
  },
  ".CloseImg": {
    opacity: 1,
    borderRadius: "50%",
    p: "2px",
    border: "1px solid #fff",
    fontSize: "0.7rem",
    path: {
      fill: "#fff",
    },
  },
  ".ContentWrapper": {
    p: 2,
  },
};
const GoalListing = {
  ul: {
    li: {
      position: "relative",
      backgroundColor: "#112225",
      pr: 1,
      pt: "6px !important",
      display: "flex",
      width: "100%",
      textDecoration: "none !important",
      "&:before": {
        display: "none !important",
      },
      ".IconWrap": {
        width: "4%",
        pt: 0,
        textAlign: "center",
        svg: {
          fontSize: "1.2rem",
          path: {
            fill: "#ffd781",
          },
        },
      },
      ".ContentWrap": {
        paddingLeft: "10px",
        width: "95%",
      },
      "&:before": {
        display: "none",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        left: "-10px",
        height: "100%",
        width: "9px",
        borderRadius: "10px",
        backgroundColor: "transparent",
        top: 0,
        bottom: 0,
      },

      "&.cancel": {
        "&:after": {
          backgroundColor: "#ffd781",
        },
      },
      "&.add": {
        "&:after": {
          backgroundColor: "#0777bd",
        },
      },
      "&.complete": {
        "&:after": {
          backgroundColor: "#709640",
        },
      },
      ".MuiButtonBase-root": {
        height: "20px",
        fontSize: "0.7rem",
        "&.Cancel": {
          backgroundColor: "#ffd781",
          color: "#000",
        },
        "&.add": {
          backgroundColor: "#0777bd",
          color: "#000",
        },
        "&.Completed": {
          backgroundColor: "#709640",
          color: "#000",
        },
      },
      ".Suggestion": {
        mr: 2,
        fontSize: "0.9rem",
        lineHeight: 1.2,
        textDecoration: "none !important",
      },
      ".progresspercent": {
        "&> p": {
          fontSize: "0.8rem !important",
        },
      },
    },
  },
};
