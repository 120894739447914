import React, { useEffect, useState } from 'react'
import { getCourses } from '../../redux/action/user';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import ResultHeader from '../../component/result/resultHeader';
import { Container, Grid, Card, CardMedia, CardContent, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import Footer from '../../component/footer';
import dummyImage from '../../assets/images/dummyImage.png'
import Loading from '../../component/Loader';

// Sample category list
const categories = [
    "Category 01",
    "Category 02",
    "Category 03",
    "Category 04",
    "Category 05",
    "Category 06",
    "Category 07",
];


const Courses = (props) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(6);

    useEffect(() => {
        if (!localStorage.getItem("userKey")) {
            navigate("/login");
        }
        props.getCourses();
    }, [])

    const courses = props?.user?.courses || [];

    const handleCategoryChange = (index) => {
        setSelected(index);
    }

    return (
        <>
            <ResultHeader />
            <section style={{ textAlign: "center", padding: "40px 0" }}>
                <Container>
                    <Typography variant="h1" component="h1" style={{ fontWeight: "bold", fontSize: '30px' }}>
                        Lorem ipsum dolor sit amet
                    </Typography>
                    <Typography variant="body1" textAlign="justify">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
                    </Typography>
                </Container>
            </section>

            {/* Section 3 */}
            <section style={{ padding: "40px 0", background: "#f9f9f9" }}>
                <Container>
                    {/* Category List */}
                    <List
                        sx={{
                            display: "flex",
                            alignItems: "stretch",
                            background: "#121c1f",
                            borderRadius: "10px",
                            padding: "0px",
                            height: "50px",
                        }}
                    >
                        {categories.map((category, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    padding: "0 20px",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    // borderRadius: index === selected ? "10px" : "0",
                                    background: index === selected ? "#f4a900" : "transparent",
                                    "&:not(:last-child)": {
                                        borderRight: "1px solid rgba(255, 255, 255, 0.3)",
                                    },
                                }}
                                onClick={() => handleCategoryChange(index)}
                            >
                                <ListItemText primary={category} />
                            </ListItem>
                        ))}
                    </List>

                    {/* Card Grid */}
                    <Box display="flex" mt={2} justifyContent="center" alignItems="center" minHeight="50vh">
                        {courses.length > 0 ? (
                            <Grid container spacing={3} justifyContent="center">
                                {courses.map((course, index) => (
                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Card sx={{ maxWidth: 345, mx: "auto" }}>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={course?.img || dummyImage}
                                                alt={course?.courseName || "No image"}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" textAlign="justify">
                                                    {course?.courseName
                                                        ? course.courseName.length > 30
                                                            ? `${course.courseName.substring(0, 30)}...`
                                                            : course.courseName
                                                        : "Unknown Course"}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" align="center" textAlign="justify">
                                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : props.user.isLoading ? (
                            <Loading />
                        ) : (
                            <Typography variant="h5" color="text.secondary">
                                No data found
                            </Typography>
                        )}
                    </Box>
                </Container>
            </section >

            <Footer />
        </>
    )
}



const actionCreators = {
    getCourses
};
export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Courses);