import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Arrow from "../../assets/images/Vector.svg"
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const CustomSlider = ({
    images = [],
    slidesPerView = 1,
    autoplayDelay = 5000,
    loop = true,
    showNavigation = true,
    showPagination = true,
    showButton = true,
    buttonText = "Start Your Free Assessment",
    buttonLink = "#"
}) => {
    const token = localStorage.getItem("userKey");
    return (
        // <section className="slider topBottomSpace">
        <Box className="main_Slider" sx={{ px: '5%' }}>
            <Box className="wrapper">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={10}
                    slidesPerView={slidesPerView}
                    navigation={showNavigation}
                    pagination={showPagination ? { clickable: true } : false}
                    autoplay={autoplayDelay ? { delay: autoplayDelay, disableOnInteraction: false } : false}
                    loop={loop}
                >
                    {images.map((src, index) => (
                        <SwiperSlide key={index}>
                            <div className="sliderimage">
                                <img src={src} alt={`Slide ${index + 1}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>

            {/* Button Below Slider */}
            {showButton && (
                <div className="buttonCSS">
                    <Link to={token ? '/result' : '/login'}>
                        <span>{buttonText}</span>
                        <img src={Arrow} alt="Right Arrow" style={{ width: "16px", height: "16px" }} />
                    </Link>
                </div>
            )}
        </Box>
        // </section>
    );
};

export default CustomSlider;
