export const clientSliderData = [
    {
        id: 1,
        image: require("../../assets/images/slide_11.png"),
        name: "Adam Ross",
        designation: "Human Resource",
        testimonial: "The good gathering doesn't bearing day Stars over Open behold May male tree replenish don't blessed beast days earth fifth."
    },
    {
        id: 2,
        image: require("../../assets/images/slidee.png"),
        name: "Sophia White",
        designation: "Marketing Manager",
        testimonial: "Amazing experience! Highly recommended for professionals looking to expand their skill set."
    },
    {
        id: 3,
        image: require("../../assets/images/slide_33.png"),
        name: "James Carter",
        designation: "Product Designer",
        testimonial: "Great platform! It helped me understand concepts deeply and apply them in my daily work."
    },
    {
        id: 4,
        image: require("../../assets/images/slide_22.png"),
        name: "Emily Johnson",
        designation: "Software Engineer",
        testimonial: "One of the best learning experiences. The UI is simple and intuitive."
    },
    {
        id: 5,
        image: require("../../assets/images/slide_11.png"),
        name: "Michael Brown",
        designation: "Business Analyst",
        testimonial: "Fantastic content! The real-world applications make learning engaging."
    }
]


export const AboutJapaList = [
    {
        title: "Autoimmune Disease",
        items: ["Hashimotos", "Lupus", "MS", "Chron’s", "Rheumatoid arthritis"],
    },
    {
        title: "Digestive Disease",
        items: ["Celiac", "Chron’s", "Ulcerative Colitis", "Irritable bowel syndrome", "Gastroesophageal reflux disease"],
    },
    {
        title: "Metabolic Disease",
        items: ["PCOS", "Type 2 Diabetes", "Cardiovascular Disease", "Cognitive Decline"],
    },
    {
        title: "Obesity",
        items: ["Hypertension", "Osteoarthritis", "Non-alcoholic Fatty Liver", "Fatigue"],
    },
    {
        title: "Immune System Issues",
        items: ["Cancer", "Allergies", "Chronic Infections", "Acne and Skin Issues"],
    },
    {
        title: "Mental, Emotional or Behavioral Disorder",
        items: ["Anxiety & Depression", "Disordered Eating", "ADHD", "Emotional Imbalances", "Autism"],
    },
];