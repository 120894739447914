import React, { useEffect, useState } from "react";
import "./profileSection.scss";
import { connect } from "react-redux";
import { updateUserData, updateUser } from "../../redux/action/user";
import LabelInput from "../../atoms/label";
import TextBox from "../../atoms/textbox";
import { FormGroup, MenuItem, Modal, Select, Typography } from "@mui/material";
import TextboxWithDropdown from "../../atoms/textboxWithDropdown";
import ToggleButtonGroupBox from "../../atoms/toggleButtonGroupBox";
import ButtonInput from "../../atoms/button";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import undraw_well_done from "../../assets/images/undraw_well_done.png";
import SnackBar from "../../atoms/snackBar";
import CenterLoading from "../core/CenterLoading";

const heightOption = [
  {
    value: "Cm",
    text: "Cm",
  },
  {
    value: "Inches",
    text: "Inches",
  },
];

const weightOption = [
  {
    value: "Kg",
    text: "Kg",
  },
  {
    value: "Pounds",
    text: "Lbs",
  },
];

// For Age Radio Button
// const ageOption = [
//     {
//         value: 'below18',
//         text: 'Below 18',
//     },
//     {
//         value: '18-25',
//         text: '18-25',
//     },
//     {
//         value: '25-50',
//         text: '25-50',
//     },
//     {
//         value: '50-75',
//         text: '50-75',
//     },
//     {
//         value: 'above75',
//         text: 'Above 75',
//     }
// ]

const ageDropDown = [
  {
    value: "-1",
    text: "Select Age",
  },
  {
    value: "18-24",
    text: "18-24",
  },
  {
    value: "25-34",
    text: "25-34",
  },
  {
    value: "35-44",
    text: "35-44",
  },
  {
    value: "45-54",
    text: "45-54",
  },
  {
    value: "55-64",
    text: "55-64",
  },
  {
    value: "65 and Over",
    text: "65 and Over",
  },
];

const genderOption = [
  {
    value: "Male",
    text: "Male",
  },
  {
    value: "Female",
    text: "Female",
  },
  {
    value: "Other",
    text: "Other",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ProfileSection = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recomdation, setRecomdation] = useState(false);

  const isSubscription =
    props.user &&
    props.user.subscription &&
    props.user.subscription.isSubscription;

  const zipCodeChange = (value) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(value) === false) {
      if (value.length < 11) {
        props.updateUserData({
          ...props.user,
          zipCode: value,
        });
      }
    }
  };

  useEffect(() => {
    if (props.user?.profileError) {
      setErrorMessage(props.user?.profileError);
    }
  }, [props.user?.profileError]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (!props.user._id && localStorage.getItem("userKey")) {
      navigate("/login");
    }

    if (
      localStorage.getItem("progressDone") !== null &&
      localStorage.getItem("detailDone") !== null
    ) {
      navigate("/result");
    }
  }, []);

  const saveUserData = () => {
    if (props.user.height === 0 || props.user.height === "") {
      setErrorMessage("Enter Height");
      return;
    } else if (props.user.weight === 0 || props.user.weight === "") {
      setErrorMessage("Enter weight");
      return;
    } else if (props.user.zipCode === "") {
      setErrorMessage("Enter zip-code");
      return;
    } else if (props.user.age === "") {
      setErrorMessage("Select Age");
      return;
    } else if (props.user.gender === "") {
      setErrorMessage("Select gender");
      return;
    }

    let tempObject = {
      height: props.user.height,
      heightunit: props.user.heightunit,
      weight: props.user.weight,
      weightunit: props.user.weightunit,
      zipcode: props.user.zipCode,
      age: props.user.age,
      gender: props.user.gender,
      id: props.user._id,
    };

    props.updateUser(tempObject, openRecomdation);
  };

  const openRecomdation = () => {
    if (isSubscription) {
      // setRecomdation(true);
      setRecomdation(false);
      localStorage.setItem("detailDone", true);
      navigate("/result");
    }
    else {
      openModal();
    }
  };

  const openModal = () => {
    // setOpen(!open);
    setRecomdation(false);
    localStorage.setItem("detailDone", true);
    navigate("/result");
  };

  const gotoProgressPage = () => {
    localStorage.setItem("detailDone", true);
    setRecomdation(true);
    navigate("/result");
  };

  const heightValueChange = (value) => {
    if (value <= 300) {
      props.updateUserData({
        ...props.user,
        height: value,
      });
    }
  };

  const setHeightFeet = (value) => {
    if (value.indexOf(".") === -1) {
      if (value <= 10) {
        let tempHeightValue = props.user.height
          ? props.user.height.split(".")
          : "";
        let tempValue = "";
        if (tempHeightValue.length > 0) {
          tempValue = value + "." + tempHeightValue[1];
        } else {
          tempValue = value + ".0";
        }
        props.updateUserData({
          ...props.user,
          height: tempValue,
        });
      }
    }
  };

  const setHeightInch = (value) => {
    if (value.indexOf(".") === -1) {
      if (value <= 12) {
        let tempHeightValue = props.user.height
          ? props.user.height.split(".")
          : "";
        let tempValue = "";
        if (tempHeightValue.length > 0) {
          tempValue = tempHeightValue[0] + "." + value;
        } else {
          tempValue = "0." + value;
        }
        props.updateUserData({
          ...props.user,
          height: tempValue,
        });
      }
    }
  };

  const heightDropDownChange = (value) => {
    props.updateUserData({
      ...props.user,
      heightunit: value,
      height: "",
    });
  };

  const weightValueChange = (value) => {
    if (value <= 300) {
      props.updateUserData({
        ...props.user,
        weight: value,
      });
    }
  };

  const weightDropDownChange = (value) => {
    props.updateUserData({
      ...props.user,
      weightunit: value,
    });
  };

  const ageValueChange = (value) => {
    if (value !== "-1") {
      props.updateUserData({
        ...props.user,
        age: value,
      });
    }
  };

  const genderValueChage = (value) => {
    props.updateUserData({
      ...props.user,
      gender: value,
    });
  };

  return (
    <div className="profile-section">
      {/* <CenterLoading open={props.user.isLoading} /> */}
      <div className="row">
        <h2 className="title yellow-font center-text">
          {props.user.language?.profileDetail?.title}
        </h2>
        <p
          className="subTitle white-color center-text avenir-bold"
          style={{ marginLeft: "50px", marginRight: "50px" }}
        >
          {props.user.language?.profileDetail?.subTitle}
        </p>
      </div>
      <FormGroup>
        <LabelInput
          style={{ color: "#fff" }}
          text={props.user.language?.profileDetail?.heightField?.title}
        />
        {props.user.heightunit === "Inches" ? (
          <div className="inches-boxes">
            <TextboxWithDropdown
              type="number"
              placeholder={
                props.user.language?.profileDetail?.heightField?.placeHolder +
                " in feet"
              }
              value={
                props.user.height &&
                  props.user.height.split(".").length > 0 &&
                  props.user.height.split(".")[0]
                  ? props.user.height.split(".")[0]
                  : ""
              }
              valueChange={setHeightFeet}
              isLabel={true}
              labelText={"Feet"}
              option={heightOption}
            />
            <TextboxWithDropdown
              type="number"
              placeholder={
                props.user.language?.profileDetail?.heightField?.placeHolder +
                " in inch"
              }
              value={
                props.user.height &&
                  props.user.height.split(".").length > 0 &&
                  props.user.height.split(".")[1]
                  ? props.user.height.split(".")[1] <= 12
                    ? props.user.height.split(".")[1]
                    : props.user.height.split(".")[1].slice(0, -1)
                  : ""
              }
              valueChange={setHeightInch}
              dropDownDefaultValue={props.user.heightunit}
              selectChange={heightDropDownChange}
              option={heightOption}
            />
          </div>
        ) : (
          <TextboxWithDropdown
            type="number"
            placeholder={
              props.user.language?.profileDetail?.heightField?.placeHolder
            }
            value={props.user.height}
            valueChange={heightValueChange}
            dropDownDefaultValue={props.user.heightunit}
            selectChange={heightDropDownChange}
            option={heightOption}
          />
        )}

        <LabelInput
          text={props.user.language?.profileDetail?.weightField?.title}
          className="margin-top-10"
          style={{ color: "#fff" }}
        />
        <TextboxWithDropdown
          type="number"
          placeholder={
            props.user.language?.profileDetail?.weightField?.placeHolder
          }
          value={props.user.weight}
          valueChange={weightValueChange}
          dropDownDefaultValue={props.user.weightunit}
          selectChange={weightDropDownChange}
          option={weightOption}
        />

        <LabelInput
          text={props.user.language?.profileDetail?.zipCodeField?.title}
          className="margin-top-10"
          style={{ color: "#fff" }}
        />
        <TextBox
          placeholder={
            props.user.language?.profileDetail?.zipCodeField?.placeHolder
          }
          type="number"
          value={props.user.zipCode}
          valueChange={zipCodeChange}
        />

        <LabelInput
          text={props.user.language?.profileDetail?.ageField?.title}
          className="margin-top-10"
          style={{ color: "#fff" }}
        />
        {/* Radio Group */}
        {/* <RadioGroupBox className="margin-top-10" option={ageOption} value={props.user.age} valueChange={ageValueChange} /> */}
        {/* Text Box */}
        {/* <TextBox
                    placeholder={props.user.language?.profileDetail?.ageField?.placeHolder}
                    type="number"
                    value={props.user.age}
                    valueChange={ageValueChange} /> */}

        {/* Drop Down */}
        <Select
          sx={{
            color: "white",
            "& fieldset": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          }}
          className={`age-drop-down`}
          variant="outlined"
          defaultValue={props.user.age ? props.user.age : -1}
          value={props.user.age ? props.user.age : -1}
          onChange={(e) => ageValueChange(e.target.value)}
          placeholder="Select age"
        >
          {ageDropDown.map((obj, index) => {
            return (
              <MenuItem
                className={`menu-item ${obj.value === props.user.age ? "selected-item" : ""
                  }`}
                value={obj.value}
                style={{ justifyContent: "center" }}
              >
                {obj.text}
              </MenuItem>
            );
          })}
        </Select>

        <LabelInput
          text={props.user.language?.profileDetail?.genderField?.title}
          className="margin-top-10"
          style={{ color: "#fff" }}
        />
        <div className="bottom-button-group margin-top-10">
          <ToggleButtonGroupBox
            option={genderOption}
            value={props.user.gender}
            valueChange={genderValueChage}
          />
          <ButtonInput
            text={"Get my score"}
            class="next-button"
            onSubmit={saveUserData}
            style={{ height: "54px", width: "162px" }}
          />
        </div>
      </FormGroup>
      <Modal open={open} onClose={openModal} className="success-modal">
        <Box sx={style} className="success-modal">
          <Typography variant="h6" component="h2" className="center-text">
            <img
              src={undraw_well_done}
              alt=""
              width={"224px"}
              height={"202px"}
            />
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            className="center-text modal-title-text "
          >
            <b className="margin-top-20 avenir-bold">
              {props.user.language?.profileDetail?.successModal?.title}
            </b>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <p className="modalDetail benton-sans-font">
              {props.user.language?.profileDetail?.successModal?.description}
            </p>
          </Typography>
          <ButtonInput
            class="modal-button"
            text={props.user.language?.profileDetail?.successModal?.buttonText}
            onSubmit={gotoProgressPage}
          />
        </Box>
      </Modal>
      {/* <Modal
        open={recomdation}
        onClose={() => openModal()}
        className="success-modal"
      >
        <Box sx={style} className="success-modal">
          <Typography sx={{ mt: 2 }}>
            <p className="modalDetail benton-sans-font">
              Select up to 5 goals that align with your lowest scores to
              maximize your benefits
            </p>
          </Typography>
          <ButtonInput
            class="modal-button"
            text={"ok"}
            onSubmit={() => openModal()}
          />
        </Box>
      </Modal> */}
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </div>
  );
};

ProfileSection.propTypes = {};
ProfileSection.defaultProps = {};

const actionCreators = {
  updateUserData,
  updateUser,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ProfileSection);
