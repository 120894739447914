import React, { useEffect } from "react";
import Header from "../../component/header";
import { Container, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router";

const PaymentFailed = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="sm" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
          <Typography variant="h4" color="error.main">
            Payment Failed
          </Typography>
          <Typography variant="body1" style={{ margin: "20px 0" }}>
            We're sorry, but your payment could not be processed. Please try
            again.
          </Typography>

          {/* {orderId && (
            <Typography variant="body2">
              <strong>Order ID:</strong> {orderId}
            </Typography>
          )} */}

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/plans")}
            style={{ marginTop: "20px" }}
          >
            Retry Payment
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              (window.location.href = "https://japa.health/contact/")
            }
            style={{ marginTop: "10px", marginLeft: "10px" }}
          >
            Contact Support
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentFailed;
